// import React, { useEffect, useState } from 'react';
// import { Layout, Row, Col } from 'antd';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from '../utils/firebase';
// import HeaderComponent from '../components/HeaderComponent';
// import TodayAttendanceCount from '../components/TodayAttendanceCount';

// const { Content } = Layout;

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A4DE6C', '#D0ED57'];

// const PersonnelRecord = () => {
//     const [studentCount, setStudentCount] = useState(0);
//     const [subjectsData, setSubjectsData] = useState([]);
//     const [schoolLevelData, setSchoolLevelData] = useState([]);

//     useEffect(() => {
//         const fetchStudentData = async () => {
//             const studentsCollection = collection(db, 'students');
//             const studentsSnapshot = await getDocs(studentsCollection);

//             // 計算學生總數
//             setStudentCount(studentsSnapshot.size);

//             // 統計每個科目的選課人數
//             const subjectCounts = {};
//             // 統計每個階段的人數
//             const schoolLevelCounts = {
//                 '幼稚園': 0,
//                 '國小': 0,
//                 '國中': 0,
//                 '高中': 0,
//                 '大學': 0,
//             };
//             studentsSnapshot.docs.forEach((doc) => {
//                 const data = doc.data();
                
//                 // 統計科目數據
//                 if (Array.isArray(data.subjects)) {
//                     data.subjects.forEach((subject) => {
//                         if (subjectCounts[subject]) {
//                             subjectCounts[subject] += 1;
//                         } else {
//                             subjectCounts[subject] = 1;
//                         }
//                     });
//                 }

//                 // 統計學習階段數據
//                 if (data.school_level) {
//                     if (schoolLevelCounts[data.school_level]) {
//                         schoolLevelCounts[data.school_level] += 1;
//                     } else {
//                         schoolLevelCounts[data.school_level] = 1;
//                     }
//                 }
//             });

//             // 轉換為 PieChart 的資料格式
//             const pieData = Object.keys(subjectCounts).map(subject => ({
//                 name: subject,
//                 value: subjectCounts[subject],
//             }));
//             setSubjectsData(pieData);

//             // 轉換為 BarChart 的資料格式
//             const levelData = Object.keys(schoolLevelCounts).map(level => ({
//                 name: level,
//                 人數: schoolLevelCounts[level],
//             }));
//             setSchoolLevelData(levelData);
//         };

//         fetchStudentData();
//     }, []);

//     const barData = [
//         { name: '', 人數: studentCount },
//     ];

//     return (
//         <Layout>
//             <HeaderComponent />
//             <Content style={{ padding: '24px', backgroundColor: 'white' }}>
//                 <Row>
//                     <Col style={{ paddingTop: '30px' }}>
//                         <TodayAttendanceCount></TodayAttendanceCount>
//                     </Col>
//                 </Row>
//                 <Row justify="center">
//                     <Col span={12}>
//                         <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>輔導室總人數</h2>
//                         <ResponsiveContainer width="100%" height={400}>
//                             <BarChart data={barData}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="name" />
//                                 <YAxis domain={[0, 200]} />
//                                 <Tooltip />
//                                 <Legend />
//                                 <Bar dataKey="人數" fill="#8884d8" />
//                             </BarChart>
//                         </ResponsiveContainer>
//                     </Col>
//                     <Col span={12}>
//                         <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>學習階段人數分佈</h2>
//                         <ResponsiveContainer width="100%" height={400}>
//                             <BarChart data={schoolLevelData}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis dataKey="name" />
//                                 <YAxis allowDecimals={false} domain={[0, 140]} tickCount={9}/> {/* 禁止顯示小數 */}
//                                 <Tooltip />
//                                 <Legend />
//                                 <Bar dataKey="人數" fill="#8884d8" />
//                             </BarChart>
//                         </ResponsiveContainer>
//                     </Col>
//                 </Row>
//                 <Row justify="center" style={{ marginTop: '24px' }}>
//                     <Col span={24}>
//                         <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>學生學習科目分佈</h2>
//                     </Col>
//                 </Row>
//                 <Row justify="center">
//                     <Col span={24}>
//                         <ResponsiveContainer width="100%" height={300}>
//                             <PieChart>
//                                 <Pie
//                                     data={subjectsData}
//                                     cx="50%"
//                                     cy="50%"
//                                     labelLine={false}
//                                     outerRadius={100}
//                                     fill="#8884d8"
//                                     dataKey="value"
//                                     label={({ name, value }) => `${name}: ${value}`}
//                                 >
//                                     {subjectsData.map((entry, index) => (
//                                         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                                     ))}
//                                 </Pie>
//                                 <Tooltip />
//                             </PieChart>
//                         </ResponsiveContainer>
//                     </Col>
//                 </Row>
//             </Content>
//         </Layout>
//     );
// };

// export default PersonnelRecord;


import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';
import { collection, getDocs } from 'firebase/firestore';
import { getDatabase, ref, get, child } from 'firebase/database'; // 新增 Realtime Database 引入
import { db } from '../utils/firebase';
import HeaderComponent from '../components/HeaderComponent';
import TodayAttendanceCount from '../components/TodayAttendanceCount';

const { Content } = Layout;
const { Option } = Select;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A4DE6C', '#D0ED57'];

const PersonnelRecord = () => {
    const [studentCount, setStudentCount] = useState(0);
    const [subjectsData, setSubjectsData] = useState([]);
    const [schoolLevelData, setSchoolLevelData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [daysRange, setDaysRange] = useState(5); // 預設查看前5天

    useEffect(() => {
        const fetchStudentData = async () => {
            const studentsCollection = collection(db, 'students');
            const studentsSnapshot = await getDocs(studentsCollection);

            // 計算學生總數
            setStudentCount(studentsSnapshot.size);

            // 統計每個科目的選課人數
            const subjectCounts = {};
            // 統計每個階段的人數
            const schoolLevelCounts = {
                '幼稚園': 0,
                '國小': 0,
                '國中': 0,
                '高中': 0,
                '大學': 0,
            };
            studentsSnapshot.docs.forEach((doc) => {
                const data = doc.data();

                // 統計科目數據
                if (Array.isArray(data.subjects)) {
                    data.subjects.forEach((subject) => {
                        if (subjectCounts[subject]) {
                            subjectCounts[subject] += 1;
                        } else {
                            subjectCounts[subject] = 1;
                        }
                    });
                }

                // 統計學習階段數據
                if (data.school_level) {
                    if (schoolLevelCounts[data.school_level]) {
                        schoolLevelCounts[data.school_level] += 1;
                    } else {
                        schoolLevelCounts[data.school_level] = 1;
                    }
                }
            });

            // 轉換為 PieChart 的資料格式
            const pieData = Object.keys(subjectCounts).map(subject => ({
                name: subject,
                value: subjectCounts[subject],
            }));
            setSubjectsData(pieData);

            // 轉換為 BarChart 的資料格式
            const levelData = Object.keys(schoolLevelCounts).map(level => ({
                name: level,
                人數: schoolLevelCounts[level],
            }));
            setSchoolLevelData(levelData);
        };

        fetchStudentData();
    }, []);

    useEffect(() => {
        const fetchAttendanceData = async () => {
            const dbRef = ref(getDatabase());
            const attendanceSnapshot = await get(child(dbRef, 'attendance')); // 從 Realtime Database 讀取 attendance 資料

            if (attendanceSnapshot.exists()) {
                const data = attendanceSnapshot.val();
                const fetchedData = [];

                Object.keys(data).forEach((date) => {
                    let count = 0;

                    // 計算每一天的打卡人數
                    Object.keys(data[date]).forEach(() => {
                        count++; // 每個記錄增加一次計數
                    });

                    fetchedData.push({
                        date: date,  // 日期
                        count: count, // 該天的打卡總人數
                    });
                });
                // console.log('daysRange:', daysRange)
                console.log('fetchedData:', fetchedData)

                // const cut_num = 10 -  daysRange
                // // console.log('cut_num:', cut_num)
                // const newArr_fetchedData = fetchedData.slice(cut_num); // 從索引5開始（即第6個元素），擷取到最後
                // 從末尾開始擷取指定的天數資料
                const newArr_fetchedData = fetchedData.slice(-daysRange);
                setAttendanceData(newArr_fetchedData); // 更新狀態
            } else {
                console.log('沒有找到數據');
            }
        };

        fetchAttendanceData();
    }, [daysRange]);

    const handleDaysRangeChange = (value) => {
        setDaysRange(value);
    };

    return (
        <Layout>
            <HeaderComponent />
            <Content style={{ padding: '24px', backgroundColor: 'white' }}>
                <Row>
                    <Col style={{ paddingTop: '30px' }}>
                        <TodayAttendanceCount />
                    </Col>
                </Row>

                {/* 折線圖部分 */}
                <Row justify="center" style={{ marginTop: '24px' }}>
                    <Col span={24}>
                        <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>選擇查看天數</h2>
                        <Select defaultValue={5} onChange={handleDaysRangeChange} style={{ width: 120 }}>
                            <Option value={5}>5天</Option>
                            <Option value={7}>7天</Option>
                            <Option value={10}>10天</Option>
                        </Select>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: '24px' }}>
                    <Col span={24}>
                        <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>最近幾天人數變化</h2>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={attendanceData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>

                {/* 原有的條形圖和餅圖 */}
                <Row justify="center">
                    <Col span={12}>
                        <h2 style={{ textAlign: 'left', marginBottom: '24px', marginTop: '24px' }}>輔導室總人數</h2>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={[{ name: '', 人數: studentCount }]}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis domain={[0, 200]} />
                                <Tooltip />
                                <Bar dataKey="人數" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col span={12}>
                        <h2 style={{ textAlign: 'left', marginBottom: '16px', marginTop: '24px' }}>學習階段人數分佈</h2>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={schoolLevelData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} domain={[0, 140]} tickCount={9}/> {/* 禁止顯示小數 */}
                                <Tooltip />
                                <Bar dataKey="人數" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: '24px' }}>
                    <Col span={24}>
                        <h2 style={{ textAlign: 'left', marginBottom: '16px' }}>學生學習科目分佈</h2>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={24}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={subjectsData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label={({ name, value }) => `${name}: ${value}`}
                                >
                                    {subjectsData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default PersonnelRecord;
